/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useDataRequestorState from '~/src/UserInterface/DataRequest/composables/useDataRequestorState'

/**
 * This middleware must be called on data requestor 2fa auth page
 *
 * This middleware checks if login has been initiated
 */
export default defineNuxtRouteMiddleware(async () => {
  const logger = useService('logger')
  logger.info('[DATA_REQUEST_AUTH_INITIATED] Middleware fired')

  const { isAuthenticationProcessInitiated } = useDataRequestorState()
  if (!isAuthenticationProcessInitiated.value) {
    logger.info('[AUTH_INITIATED] Process is not initiated, redirecting')

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
  }
})
